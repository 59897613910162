<template>
  <div style="display: flex;">
    <div class="currency-hedge">
        <h1>Cobertura de divisa</h1>
        <Table :table_header="table_header" :table_body="table_body" />
    </div>

    <div class="currency-diversification">
        <h1>Diversificación de divisas</h1>
        <figure class="highcharts-figure">
            <div id="container"></div>
        </figure>
    </div>
  </div>
</template>
<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import Table from '@/components/helpers/Table.vue';
import Highcharts from "highcharts";
//import Vue from 'vue';

export default {
  mixins: [APICaller],  
  name: 'CurrencyHedgeGeneral',
  props:{
    portfolio_idc: {type: String}
  },
  components: {
    Table,
  },
  data() {
    return {
        table_header: ['Divisa', 'Tienes', 'Cobertura', 'Total'],
        table_body: [],
        diversification: "",
        coverages: false,
        full_coverages_data: false,
    };
  },
  watch: {
  },
  created(){
    
  },
  mounted(){
      this._printDiversification();
      this._getDiversification();
      this._getCoverages();
      
  },
  computed() {       
  },
  methods: {
    _getDiversification: function() {
      let success = response => {
        this.diversification = response.data;
        this._printDiversification();
      }

      let url = '/api/v1/portfolio/get-currency-diver/' + this.portfolio_idc

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('217', 'Error recuperando diversificación.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _printDiversification: function() {
        Highcharts.chart('container', {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: ''
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                name: 'Brands',
                colorByPoint: true,
                data: [{
                    name: 'Chrome',
                    y: 61.41,
                    sliced: true,
                    selected: true
                }, {
                    name: 'Internet Explorer',
                    y: 11.84
                }, {
                    name: 'Firefox',
                    y: 10.85
                }, {
                    name: 'Edge',
                    y: 4.67
                }, {
                    name: 'Safari',
                    y: 4.18
                }, {
                    name: 'Sogou Explorer',
                    y: 1.64
                }, {
                    name: 'Opera',
                    y: 1.6
                }, {
                    name: 'QQ',
                    y: 1.2
                }, {
                    name: 'Other',
                    y: 2.61
                }]
            }]  
        });
    },

    _getCoverages: function() {

      let success = response => {
        this.coverages = response.data.coverages;
        this.full_coverages_data = response.data.full_coverages_data;
        this.formatCoverageDecimals();
      }

      let url = '/api/v1/portfolio/coverages/' + this.portfolio_idc

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('218', 'Error recuperando coberturas.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _formatCoverageDecimals: function() {
      for (var i = 0; i < this.coverages.length; i++) {
        this.coverages[i].coverage_done = this.$options.filters.round(this.coverages[i].coverage_done, 2);
      }
    },

    _updateCoverageDone: function(index) {
      var params = new URLSearchParams();
      params.append('currency', this.coverages[index].currency);
      params.append('coverage', this.coverages[index].coverage_done);

      let success = () => {
        this._getCoverages();
        this.$vueOnToast.pop('success', "Coverage Updated successfully!");
      }

      let url = '/api/v1/portfolio/coverage/update-currency/' + this.portfolio_id

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('303', 'Error actualizando cobertura.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },
  }
  
}

</script>
<style lang="scss" scoped>
@use "@material/data-table/data-table";
@include data-table.core-styles;
.currency-hedge{
    width: 50%;
}
.currency-diversification{
    width: 50%;
}
h1{
    margin-left: 20%;
}

.mdc-data-table__header-cell,
.mdc-data-table__cell {
    color: rgba(0, 0, 0, 0.6);
    background-color: #F3F4F7;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;}
  
.mdc-data-table {
    margin-left: 5%;
    width: 75%;}
  
.mdc-data-table__row,
.mdc-data-table__cell {
    background-color: white;
    color: #B00020;}
.simulator-table {
    margin: 19px 44px 24px;
}

.highcharts-figure,
.highcharts-data-table table {
    min-width: 320px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

input[type="number"] {
    min-width: 50px;
}


</style>