<template>
    <div class="autocomplete-input">
        <div class="control">
          <input 
            :disabled="disabled" 
            ref="keyword" 
            required 
            v-model="keyword" 
            class="form-control" 
            :placeholder="placeholder" 
            @input="_onInput($event.target.value)" 
            @keyup.esc="isOpen = false" 
            @blur="_handleBlur"
            @keydown.down="_moveDown" 
            @keydown.up="_moveUp" 
            @keydown.enter="_select">
          <div class="input-icon" v-if="keyword">
            <i class="material-icons clear-icon" @click="_clearInput">close</i>
          </div>
          <div class="input-icon" v-else>
            <i class="material-icons search-icon">search</i>
          </div>
        </div>
        <teleport to="body" v-if="isOpen">
          <div class="autocomplete-portal">
            <transition name="dropdown">
              <div v-show="isOpen" class="dropdown-container" :style="dropdownStyle">
                <ul v-if="options.length > 0" class="options-list">
                  <li v-for="(option, index) in options" :key="index" :class="{
                  'highlighted': index === highlightedPosition
                  }" @mouseenter="highlightedPosition = index" @mousedown.prevent="_select">
                    <slot name="item" :title="option.title" :description="option.description" :thumbnail="option.thumbnail"></slot>
                  </li>
                </ul>
                <div v-else-if="keyword" class="no-results">
                  <p>No se encontraron resultados</p>
                </div>
              </div>
            </transition>
          </div>
        </teleport>
    </div>
</template>

<script>
export default {
    name: 'AutocompleteInput',
    props: {
      options: {
        type: Array,
        required: true,
      },
      show: {type: Boolean, default: false},
      disabled: {type: Boolean, default: false},
      placeholder: {type: String, default: "Buscar..."}
    },
    data: () => ({
      isOpen: false,
      highlightedPosition: 0,
      keyword: '',
      dropdownPosition: {
        top: 0,
        left: 0,
        width: 0
      }
    }),
    watch: {
      show: function() {
        this.keyword = '';
        if(this.show) {
          this.$refs.keyword.focus();
        }
      },
      options: function(newVal) {
        // Reset highlighted position when options change
        this.highlightedPosition = 0;
      },
      isOpen: function(newVal) {
        if (newVal) {
          this._updateDropdownPosition();
          // Add event listener for window resize to update dropdown position
          window.addEventListener('resize', this._updateDropdownPosition);
          window.addEventListener('scroll', this._updateDropdownPosition, true);
        } else {
          window.removeEventListener('resize', this._updateDropdownPosition);
          window.removeEventListener('scroll', this._updateDropdownPosition, true);
        }
      }
    },
    computed: {
      _filterOptions: function() {
        const re = new RegExp(this.keyword, 'i');
        return this.options.filter((o => o.title.match(re)) || (d => d.description.match(re)))
      },
      dropdownStyle: function() {
        return {
          top: `${this.dropdownPosition.top}px`,
          left: `${this.dropdownPosition.left}px`,
          width: `${this.dropdownPosition.width}px`,
          maxWidth: `${this.dropdownPosition.width}px`,
        };
      }
    },
    created() {},
    mounted() {
      // Add focus if show is true
      if (this.show) {
        this.$nextTick(() => {
          this.$refs.keyword.focus();
        });
      }
    },
    methods: {
      _onInput: function(value) {
        this.highlightedPosition = 0;
        this.isOpen = true;

        this.$emit('suggestions', value);
        if(value == '') {
          this.isOpen = false;
        }
      },
      _moveDown: function() {
        if(!this.isOpen || this.options.length === 0) {
          return
        }
        this.highlightedPosition = (this.highlightedPosition + 1) % this.options.length;
        this._scrollToHighlighted();
      },
      _moveUp: function() {
        if(!this.isOpen || this.options.length === 0) {
          return
        }
        this.highlightedPosition = this.highlightedPosition - 1 < 0 ? this.options.length - 1 : this.highlightedPosition - 1;
        this._scrollToHighlighted();
      },
      _scrollToHighlighted: function() {
        this.$nextTick(() => {
          const highlighted = document.querySelector('.autocomplete-portal .highlighted');
          if (highlighted) {
            highlighted.scrollIntoView({ block: 'nearest' });
          }
        });
      },
      _select: function() {
        if (!this.options.length || this.highlightedPosition >= this.options.length) return;
        
        const selectedOption = this.options[this.highlightedPosition];
        this.$emit('select', selectedOption);
        this.isOpen = false;
        this.keyword = selectedOption.title;
        setTimeout(this._remove_value_from_input, 500);
      },
      _remove_value_from_input: function() {
        this.keyword = "";
      },
      _clearInput: function() {
        this.keyword = "";
        this.isOpen = false;
        this.$refs.keyword.focus();
      },
      _handleBlur: function() {
        // Delay closing dropdown to allow click events on options
        setTimeout(() => {
          this.isOpen = false;
        }, 200);
      },
      _updateDropdownPosition: function() {
        this.$nextTick(() => {
          if (!this.$refs.keyword) return;
          
          const inputRect = this.$refs.keyword.getBoundingClientRect();
          
          // Calculate position relative to viewport
          this.dropdownPosition = {
            top: inputRect.bottom + window.scrollY + 6, // 6px gap
            left: inputRect.left + window.scrollX,
            width: inputRect.width
          };
        });
      }
    }
}
</script>

<style lang="scss" scoped>
  .autocomplete-input {
    position: relative;
    width: 100%;
  }

  .control {
    position: relative;
    width: 100%;
    margin: 0;
  }

  input {
    width: 100%;
    height: 46px;
    padding: 8px 16px;
    padding-right: 40px;
    font-size: 16px;
    border: 1px solid var(--color-outline, rgba(15, 15, 26, 0.2));
    border-radius: 8px;
    background-color: var(--color-background, white);
    color: var(--color-text-primary, #0F0F1A);
    transition: all 0.25s ease;
    box-shadow: 0 2px 4px rgba(15, 15, 26, 0.05);
    box-sizing: border-box;
    &::placeholder {
      color: var(--color-text-secondary, #606577);
      opacity: 0.6;
    }
    &:focus {
      outline: none;
      border-color: var(--color-highlight, #00aedd);
      box-shadow: 0 0 0 3px rgba(0, 174, 221, 0.15), 0 2px 8px rgba(15, 15, 26, 0.08);
      transform: translateY(-1px);
    }
    &:disabled {
      background-color: var(--color-surface, #F3F4F7);
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    i {
      font-size: 20px;
      color: var(--color-text-secondary, #606577);
    }
    .clear-icon {
      cursor: pointer;
      &:hover {
        color: var(--color-highlight, #00aedd);
      }
    }
  }
</style>

<style lang="scss">
  .autocomplete-portal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 9999;
    overflow: visible;
    pointer-events: none;
  }

  .dropdown-container {
    position: absolute;
    margin-top: 0;
    overflow: visible;
    pointer-events: auto;
  }

  .options-list {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--color-background, white);
    border-radius: 8px;
    box-shadow: 0 6px 16px rgba(15, 15, 26, 0.12);
    padding: 6px 0;
    list-style: none;
    margin: 0;
    border: 1px solid var(--color-outline, rgba(15, 15, 26, 0.12));
    box-sizing: border-box;

    /* Scrollbar styling */
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: var(--color-surface, #F3F4F7);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--color-outline, rgba(15, 15, 26, 0.2));
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: var(--color-text-secondary, #606577);
    }
  }

  .options-list li {
    width: 100%;
    padding: 10px 16px;
    cursor: pointer;
    transition: background-color 0.15s ease;
    border-bottom: 1px solid rgba(15, 15, 26, 0.05);
    &:last-child {
      border-bottom: none;
    }
    &:hover, &.highlighted {
      background-color: var(--color-active-surface, #d9ecf3);
    }
  }

  .no-results {
    width: 100%;
    padding: 16px;
    background-color: var(--color-background, white);
    border-radius: 8px;
    box-shadow: 0 6px 16px rgba(15, 15, 26, 0.12);
    text-align: center;
    border: 1px solid var(--color-outline, rgba(15, 15, 26, 0.12));
    box-sizing: border-box;
    
    p {
      color: var(--color-text-secondary, #606577);
      font-style: italic;
      margin: 0;
      font-size: 14px;
    }
  }

  /* Animation */
  .dropdown-enter-active, .dropdown-leave-active {
    transition: all 0.25s ease;
  }
  .dropdown-enter, .dropdown-leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }
</style>