<template>
  <div>
    <h1>Índice de cartera</h1>
    <div style="display: flex;">
      <div id="simulator">
        <div id="simulator-full-screen">
          <div class="simulator-table">
            <div class="mdc-data-table mdc-data-table">
              <div class="mdc-data-table__table-container">
                <table class="mdc-data-table__table" aria-label="Dessert calories">
                  <thead>
                    <tr class="mdc-data-table__header-row">
                      <th v-for="(item, index) in table_header" :key="index" class="mdc-data-table__header-cell" role="columnheader" data-column-id="date" scope="col">{{item}}</th>
                    </tr>
                  </thead>
                  <tbody class="mdc-data-table__content">
                    <tr class="mdc-data-table__row">
                      <th class="mdc-data-table__cell" :style="{color: _colorProfitIndex(profit)}">{{profit}}</th>
                      <th class="mdc-data-table__cell">{{max_drawdown}}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <div class="simulador-input-autocomplete">
              <AutocompleteInput
                :options="options"
                :show="show"
                :disabled="disabled_ticker_to_check"
                @select="_selectSuggestions"
                @suggestions="_autocompleteSuggestions">
                <template slot="item" scope="option">
                  <article class="media">
                    <p>
                      <strong>{{ option.title }}</strong>
                      <br> {{ option.description }}
                    </p>
                  </article>
                </template>
              </AutocompleteInput>
            </div>
          </div>
        </div>
      </div>
          
      <div class="simulador-highchart">
        <div id="highchart-content"></div>
      </div>
    </div>
  </div>
</template>

<script> 
import APICaller from "@/components/codeComponents/APICaller.js";
//import Table from '@/components/helpers/Table.vue';
import Highcharts from "highcharts";
import Vue from 'vue';
import AutocompleteInput from "@/components/helpers/AutocompleteInput";
import { MDCDataTable } from '@material/data-table';

export default {
  mixins: [APICaller],  
  name: 'PortfolioIndexGeneral',
  props:{
      portfolio_idc: {type: String}
  },
  components: {
    AutocompleteInput,
    //Table,
  },
  data() {
    return {
        show: true,
        disabled_ticker_to_check: false,
        options: [],
        user_tickers: [],
        series: [{
        name: 'Indice de cartera',
        data: [0, 0]
        }],
        categories: [],
        user_portfolios: [],

        table_header: ['Rentabilidad', 'Max.Drawdown'],
        profit: 0,
        max_drawdown: 0,

    };
  },
  watch: {
    profit: function(){
      var color = this._colorProfitIndex(this.profit);
      document.getElementById("profit-text").style.color = color;
    }
  },
  created(){
  },
  mounted(){
    new MDCDataTable(document.querySelector('.mdc-data-table'));
    this._addDataToHighchart();
    this._getPortfolios();
    this._getData();
  },
  computed() {       
  },
  methods: {
    _selectSuggestions: function(option) {
      this.user_tickers.push(option.title);

      this._getData();
    },
    _autocompleteSuggestions: function(option) {
      this.options = [];
      this.ticker = option;
      this.currency = 'EUR';
      this.entries_analysis = [];

      let success = response => {
        this.options = [];

        for (var key in response.data) {
          this.options.push({ title: key, description: response.data[key] });
        }
      };

      let url = '/api/v1/portfolio/waitings/autocomplete/' + option;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('219', 'Error cargando auocompletado.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _getData: function(){
      //let params = new URLSearchParams();
      //params.append('tickers', this.user_tickers.toString());

      let query = "?tickers=" + this.user_tickers.toString();

      let success= response => {
        this.series = response.data['series'];
        this.categories = response.data['categories'][0].data;
        this.max_drawdown = response.data['max_drawdown'];
        this.profit = response.data['profit'];
        this._addDataToHighchart();
      };

      let url = "/api/v1/portfolio/calculate-portfolio-index-from-tickers" + query;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('220', 'Error calculando índice de cartera.');

      this._getAPICall(url, successHandler, failureHandler);

    },
    _getPortfolios() {
      let success = response =>  {
        this.portfolios = response.data;
        this._getPortfolioData()
      }

      let url = '/api/v1/portfolio/list'

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('221', 'Error recuperando la lista de carteras.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _getPortfolioData: function(){
      this.portfolio_idc = this.$route.params.idc;
      this.portfolios.forEach((element) =>{
        if(element.idc == this.portfolio_idc){
          this.portfolio_name = element.name;
        }
      });
      
    },  
    _colorProfitIndex: function(profit){
      let color;
      if(profit == 0){
        color = "#B00020";
        return color;
      }
      else if(profit < 0){
        color = 'red';
        return color;
      } else{
        color= 'green';
        return color;
      }
    },

    _addDataToHighchart: function(){
      let self = this;
      Vue.nextTick(function() {
        Highcharts.chart('highchart-content',{
          chart: {
            type: 'line',
            zoomType: 'x',
          },
          title: {
            text: '',
          },
          xAxis: {
            visible: false,
          },
          yAxis: {
            title: '',
            type: 'logarithmic',
          },
          tooltip: {
            shared: true,
            crosshairs: true
          },
          series: self.series,
          colors: ['#4572A7', '#a4a5a7'],
        });
      })
    },
  }
  
}

</script>

<style lang="scss" scoped>
  @use "@material/data-table/data-table";
  @include data-table.core-styles;

  .mdc-data-table__header-cell,
  .mdc-data-table__cell {
    color: rgba(0, 0, 0, 0.6);
    background-color: #F3F4F7;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;}
  
  .mdc-data-table {
    width: 60%;}
  
  .mdc-data-table__row,
  .mdc-data-table__cell {
    background-color: white;
    color: #B00020;}
  .simulator-table {
    margin: 19px 44px 24px;
  }
  .simulador-highchart {
    margin: 0 10px;
    border: 0.5px solid rgba(0, 0, 0, 0.6);
    width: 70%;
  }
  .info-ticker-box {
     width: 100%;
     display: flex;
     justify-content: center;
  }
  .text-info-ticker {
    text-align: center;
    background-color: #F3F4F7;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 16px;
    padding: 7px 25px;

    span {
      font-size: 18px;
    }
  }
  .text-bold {
    font-weight: bold;
  }

  .simulator-button-add {
    background-color: #00AEDD;
    border-radius: 50%;
    color: #FFFFFF;
    border: none;
    margin-left: 20px;
    padding: 3px 5px 1px;
    box-shadow: 0px 1px 3px gray;
    cursor: pointer;
  }

  .simulator-chips-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin: 22px 10px 0;
  }

  .simulator-chips-box {
    display: flex;
    margin: 0 6px 12px;
    font-size: 14px;
    line-height: 20px;
    border: 0.5px solid rgba(0, 0, 0, 0.62);
    color: #000010;
    border-radius: 16px;
    padding: 5px 10px;
  }

  .mdc-button {
    margin-top: 20px;
  }

  .icon-delete {
    cursor: pointer;
    font-size: 20px;
    color: #606577;
    margin-left: 10px;
  }

  .simulador-button-save-index {
    text-align: center;
  }

  hr {
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin: 20px 37px 43px;;
  }

  .simulator-title-index {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #0F0F1A;
    margin-left: 37px;
  }

  .simulator-chips-margin {
    margin: 5px 30px 30px;
  }

  .simulador-input-autocomplete {
    text-align: left;
    margin-left: 45px;
    width: 75%;
  }

  .label-autocomplete-input {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.38);
  }
  .control {
    margin: 0;
  }

  #highchart-content {
    height: 330px;
    width: 100%;
  }
  .highcharts-root {
    width: 380px !important;
  }
  #simulator{
      width: 40%;
  }
</style>