<template>
  <div>
    <h1>Riesgo de cartera</h1>
    <div class="simulador-highchart">
      <div id="highchart-content-portfolio-risk"></div>
    </div>
  </div>
</template>
<script>
import APICaller from "@/components/codeComponents/APICaller.js";
//import Table from '@/components/helpers/Table.vue';
import Highcharts from "highcharts";
import Vue from 'vue';

export default {
  mixins: [APICaller],  
  name: 'PortfolioRiskGeneral',
  props:{
    portfolio_idc: {type: String}
  },
  components: {
  },
  data() {
    return {
      series: [
        {
          name: 'Riesgo alcista ',
          data: 0,
          color: '#00add8'
        },
        {
          name: 'Riesgo bajista  ',
          data: 0,
          color: '#444242'
        },
        {
          name: 'Riesgo alcista virtual  ',
          data: 0,
          color: 'rgb(137, 165, 78)'
        },
        {
          name: 'Riesgo bajista virtual  ',
          data: 0,
          color: 'rgb(128, 105, 155)'
        }
      ],
    };
  },
  watch: {
  },
  created(){
    
  },
  mounted(){
    this._getPorfolioRisk();
    this._addDataPorfolioRiskToHighchart();
  },
  computed() {       
  },
  methods: {
    _addDataPorfolioRiskToHighchart: function(){
      let self = this;
      Vue.nextTick(function() {
        Highcharts.chart('highchart-content-portfolio-risk',{
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          subtitle: {
            text: 'Eurekers'
          },
          xAxis: {
            categories: [''],
            crosshair: true
          },
          yAxis: {
            min: 0,
            max: Math.max(self.series[0].data, self.series[1].data,self.series[2].data, self.series[3].data, 8),
            title: {
              text: 'Riesgo (%)'
            },
            plotLines: [{
              value: 6,
              color: '#57c6f9',
              dashStyle: 'shortdash',
              width: 2,
              label: {
                text: '6 %'
              }
            }]
          },
          tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true,
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            }
          },
          series: self.series,
        });
      })
    },
    _getPorfolioRisk() {
      let success = response => {
        // this.series = response.data 
        this.series[0].data = response.data [0].data[0].y
        this.series[1].data = response.data [1].data[0].y
        this.series[2].data = response.data [2].data[0].y
        this.series[3].data = response.data [3].data[0].y
        this._addDataPorfolioRiskToHighchart()
      }

      let url = '/api/v1/portfolio/get-risk/' + this.portfolio_idc;
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('222', 'Error recuperando el riesgo de cartera.');

      this._getAPICall(url, successHandler, failureHandler);
    },
  }
  
}

</script>
<style lang="scss" scoped>

.simulador-highchart{
  margin-left: 5%;
  width: 90%;
}

#highchart-content {
  height: 330px;
  width: 100%;
}
</style>