<template>
  <div>
    <h1>Curva de capital</h1>
    <div class="simulador-highchart">
      <div id="highchart-content-curve"></div>
    </div>
  </div>
</template>
<script>
import APICaller from "@/components/codeComponents/APICaller.js";
//import Table from '@/components/helpers/Table.vue';
import Highcharts from "highcharts";
import Vue from 'vue';

export default {
  mixins: [APICaller],  
  name: 'CurveCapitalGeneral',
  props:{
    portfolio_idc: {type: String}
  },
  components: {
  },
  data() {
    return {
    };
  },
  watch: {
  },
  created(){
  },
  mounted(){
    this._getCurveCapital();
  },
  computed() {       
  },
  methods: {
    _addDataCurveCapitalToHighchart: function(){
      let self = this;
      Vue.nextTick(function() {
        Highcharts.chart('highchart-content-curve',{
          chart: {
                    type: 'line',
                    zoomType: 'x',
                    height: 180,
                  },
                  title: {
                      text: '',
                    },
                  xAxis: {
                      type: 'datetime',
                      dateTimeLabelFormats: {
                          year: '%b'
                      },
                  },
                  yAxis: {
                      title: '',
                  },
                  tooltip: {
                      shared: true,
                      crosshairs: true
                  },
                  plotOptions: {
                      line: {
                          marker: {
                              enabled: false
                          }
                      }
                  },
                  series: self.series,
        });
      })
    },
    _getCurveCapital() {
      let success = response => {
        this.series = response.data.series;
        this._addDataCurveCapitalToHighchart();
      }
       
      let url = '/api/v1/portfolio/get-capital-curve/' + this.portfolio_idc;
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('216', 'Error recuperando la curva de capital.');

      this._getAPICall(url, successHandler, failureHandler);
    },
  }
  
}

</script>
<style lang="scss" scoped>
#highchart-content {
  height: 330px;
  width: 100%;
}
</style>